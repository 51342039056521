<template>
    <section id="cta" class="wrapper style3">
      <div class="container">
        <header>
          <h2>Please authenticate to view this content</h2>
          <a v-on:click="signIn()" class="button">Sign In</a>
        </header>
      </div>
    </section>
</template>

<script>
import { Auth } from 'aws-amplify'

export default {
  name: 'AuthenticationView',
  methods: {
    signIn: function () {
      Auth.federatedSignIn().catch(err => console.log(err))
    }
  }
}
</script>

<style scoped>
</style>
