<template>
  <section class="wrapper style1">
    <div class="container">
      <div id="content">
        <table class="default">
          <thead>
            <tr>
              <th>Artifact</th>
              <th>Version</th>
              <th>Download/View</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>INCA Accounts Management</td>
              <td>2022-01-cormorant-2</td>
              <td>
                <ProcessingButton
                  ref="downloadIncaButton"
                  class="vue-link"
                  v-on:click="downloadInca()"
                  v-bind:text="'Download'"
                  v-bind:stateEventSuccess="'DownloadIncaEventSuccess'"
                  v-bind:stateEventFailure="'DownloadIncaEventFailure'"
                  v-bind:spaced="false"
                />
              </td>
            </tr>
            <tr>
              <td>Gameday Framework</td>
              <td>3.0.1</td>
              <td>
                <ProcessingButton
                  ref="downloadGamedayFrameworkButton"
                  class="vue-link"
                  v-on:click="downloadGamedayFramework()"
                  v-bind:text="'Download'"
                  v-bind:spaced="false"
                />
              </td>
            </tr>
            <tr>
              <td>Gameday Platform</td>
              <td>3.0.1</td>
              <td>
                <ProcessingButton
                  ref="downloadGamedayPlatformButton"
                  class="vue-link"
                  v-on:click="downloadGamedayPlatform()"
                  v-bind:text="'Download'"
                  v-bind:spaced="false"
                />
              </td>
            </tr>
            <tr>
              <td>Gameday Documentation</td>
              <td>3.0.1</td>
              <td>
                <ProcessingButton
                  ref="viewGamedayDocumentationButton"
                  class="vue-link"
                  v-on:click="viewGamedayDocumentation()"
                  v-bind:text="'ViewOnline'"
                  v-bind:spaced="false"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import CloudEndpointService from "@/services/cloud-endpoint";
import ProcessingButton from "@/components/ProcessingButton";

const cloudEndpoint = new CloudEndpointService();

export default {
  name: "ArtifactsView",
  components: {
    ProcessingButton
  },
  methods: {
    setSignedCookie: function (cookie) {
        document.cookie = "CloudFront-Policy=" + cookie["CloudFront-Policy"] + "; Path=/; SameSite=Strict; Secure";
        document.cookie = "CloudFront-Signature=" + cookie["CloudFront-Signature"] + "; Path=/; SameSite=Strict; Secure";
        document.cookie = "CloudFront-Key-Pair-Id=" + cookie["CloudFront-Key-Pair-Id"] + "; Path=/; SameSite=Strict; Secure";
    },
    downloadInca: function () {
      const that = this
      cloudEndpoint.getSignedCookie().then((result) => {
        that.setSignedCookie(result)
        that.$refs.downloadIncaButton.processSuccess()
        window.open(process.env.VUE_APP_WEBSITE_URL + "/artifacts/inca/2022-01-cormorant-2/all/inca.zip", "_blank");
      });
    },
    downloadGamedayFramework: function () {
      const that = this
      cloudEndpoint.getSignedCookie().then((result) => {
        that.setSignedCookie(result)
        that.$refs.downloadGamedayFrameworkButton.processSuccess()
        window.open(process.env.VUE_APP_WEBSITE_URL + "/artifacts/GamedayFramework/3.0.1/all/GamedayFramework.zip", "_blank");
      });
    },
    downloadGamedayPlatform: function () {
      const that = this
      cloudEndpoint.getSignedCookie().then((result) => {
        that.setSignedCookie(result)
        that.$refs.downloadGamedayPlatformButton.processSuccess()
        window.open(process.env.VUE_APP_WEBSITE_URL + "/artifacts/revolve-gameday-platform/3.0.1/all/revolve-gameday-platform.zip", "_blank");
      });
    },
    viewGamedayDocumentation: function () {
      const that = this
      cloudEndpoint.getSignedCookie().then((result) => {
        that.setSignedCookie(result)
        that.$refs.viewGamedayDocumentationButton.processSuccess()
        window.open(process.env.VUE_APP_WEBSITE_URL + "/artifacts/GamedayFramework/3.0.1/all/docs/", "_blank");
      });
    },
  },
};
</script>

<style scoped>
</style>
