import { createRouter, createWebHashHistory } from 'vue-router'

// Root
import HomeView from '@/views/HomeView'
import MainViewContainer from '@/views/MainViewContainer'

// Main views
import LearnMoreView from '@/views/main/LearnMoreView'
import AuthenticationView from '@/views/main/AuthenticationView'
import ArtifactsView from '@/views/ArtifactsView'

// State store
import Store from '@/store'

import { Auth, Hub } from 'aws-amplify'

// Define getUser function
async function getUser() {
  return Auth.currentAuthenticatedUser().then((data) => {
    if (data) {
      // When current authenticated user found, store it in local storage
      const userEmail = data.signInUserSession.idToken.payload.email
      Store.commit('account/login', userEmail)
      // Return user
      return data
    } else {
      // By default, remove elements from local storage
      Store.commit('account/logout')
      return null
    }
  }).catch(() => {
    // When current authenticated user not found, drop local storage
    Store.commit('account/logout')
    return null
  })
}

const router = createRouter({
  history: createWebHashHistory(),
  routes: [{
    path: '/',
    component: HomeView
  }, {
    path: '/main',
    component: MainViewContainer,
    children: [{
      path: 'auth',
      component: AuthenticationView
    }, {
      path: 'learn-more',
      component: LearnMoreView
    }]
  },
  {
    path: '/artifacts',
    component: ArtifactsView,
    meta: {
      requiresAuth: true
    },
  }]
})

// Firewall:
//  - If user is not authenticated, and page requires auth, redirect to authentication view
//  - If user clicked or followed a tunnel link without being in a tunnel path, redirect to last corresponding step.
router.beforeResolve(async (to, from, next) => {
  // Authentication redirection
  const user = await getUser()
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!user) {
      return next({
        path: '/main/auth'
      })
    }
  }

  return next()
})

// Authentication events
Hub.listen('auth', async data => {
  switch (data.payload.event) {
    case 'signIn':
      await getUser()
      router.push({ path: '/artifacts' }, () => { })
      break
    case 'signIn_failure':
      await getUser()
      router.push({ path: '/' }, () => { })
      break
    case 'signOut':
      await getUser()
      router.push({ path: '/' }, () => { })
      break
    // Below, the unused codes
    // case 'SignUpView':
    // case 'tokenRefresh':
    // case 'tokenRefresh_failure':
    // case 'configured':
  }
})

export default router
