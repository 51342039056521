import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Appsync & Amplify imports
import Amplify from 'aws-amplify'

// Styles
import '@/assets/css/main.css'
import '@/assets/css/revolve.css'
import '@/assets/css/fontawesome-all.min.css'

// Init AWS Amplify
Amplify.configure({
  Auth: {
    // Identity pool configuration
    identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.VUE_APP_COGNITO_REGION,
    identityPoolRegion: process.env.VUE_APP_AWS_COGNITO_IDENTITY_REGION,

    // User pool configuration
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,

    // Mandatory sign in
    mandatorySignIn: true,

    // Hosted UI configuration
    oauth: {
      domain: process.env.VUE_APP_COGNITO_DOMAIN,
      scope: process.env.VUE_APP_COGNITO_SCOPES.split(','),
      redirectSignIn: process.env.VUE_APP_WEBSITE_URL,
      redirectSignOut: process.env.VUE_APP_WEBSITE_URL,
      responseType: 'code'
    }
  },
  API: {
    endpoints: [
      {
        name: 'api',
        endpoint: process.env.VUE_APP_API_ENDPOINT,
        region: process.env.VUE_APP_AWS_API_REGION
      }
    ]
  }
})

const app = createApp(App)
  .use(router)
  // It is not required to install this store as a plugin,
  // but convenient to use this.$userStore.commit without importing it
  .use(store)
  .mount('#app')

export default app
