<template>
  <button type="button" class="btn btn-outline-secondary"
    v-on:click="onButtonClicked()"
    v-bind:class="{'spaced': spaced, 'blinking': blinking}"
    :disabled="processing || disabled">
    <i v-if="processing" class="fa fa-spinner fa-spin"></i>
    {{text}}
    <i v-if="success" class="fa fa-check green-tick"></i>
    <i v-if="failure" class="fa fa-times red-cross"></i>
  </button>
</template>

<script>

export default {
  name: 'ProcessingButton',
  props: {
    text: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    spaced: {
      type: Boolean,
      default: true
    },
    blinking: {
      type: Boolean,
      default: false
    },
    stateEventSuccess: {
      type: String
    },
    stateEventFailure: {
      type: String
    }
  },
  data: function () {
    return {
      processing: false,
      success: false,
      failure: false
    }
  },
  methods: {
    onButtonClicked: function() {
      // Start spinning
      this.processing = true
    },
    processSuccess: function () {
      // Display success check for 3 seconds
      const that = this
      this.processing = false
      this.success = true
      setTimeout(function () { that.success = false }, 3000)
    },
    processFailure: function () {
      // Display failure cross for 3 seconds
      const that = this
      this.processing = false
      this.failure = true
      setTimeout(function () { that.failure = false }, 3000)
    }
  }
}
</script>

<style scoped>
.blinking {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.spaced {
  margin: 10px 0;
}

button:disabled {
  cursor: default;
  opacity: 0.65;
}
button:disabled:hover {
		background-color: #ff0000;
}
</style>
