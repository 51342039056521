<template>
  <main>
    <section class="wrapper style1">
      <div class="container">
        <div id="content">
          <router-view />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  computed: {
    isAuthenticated() {
      // UserStore is installed as a plugin
      return this.$userStore.state.userEmail != null;
    },
  },
};
</script>

<style scoped>
</style>
