import { mapGetters } from 'vuex'

// initial state
const state = () => ({
  email: null
})

// getters
const getters = {
  isAuthenticated (state) {
    return state.email != null
  }
}

// actions
const actions = {}

// mutations
const mutations = {
  login (state, email) {
    state.email = email
  },
  logout (state) {
    state.email = null
  }
}

const computed = {
  // mix the getters into computed with object spread operator
  ...mapGetters([
    'isAuthenticated'
  ])
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  computed
}
