<template>
	<body class="is-preload">
		<div id="page-wrapper">
      <NavComponent />
      <router-view />
      <FooterComponent />
    </div>
  </body>
</template>

<script>
import NavComponent from './components/NavComponent.vue'
import FooterComponent from './components/FooterComponent.vue'

export default {
  name: 'App',
  components: {
    NavComponent,
    FooterComponent
  }
}
</script>

<style>
</style>
