<template>
  <div id="header">
    <h1>
      <router-link to="/" id="logo"><img src="@/assets/images/revolve-small.png" /></router-link>
    </h1>
    <nav id="nav">
      <ul>
        <li :class="computeCurrentClass('/')"><router-link to="/">Home</router-link></li>
        <li :class="computeCurrentClass('/main/learn-more')"><router-link to="/main/learn-more">Learn More</router-link></li>
        <li v-if="isAuthenticated" :class="computeCurrentClass('/artifacts')"><router-link to="/artifacts">Artifacts</router-link></li>
        <li v-if="!isAuthenticated"><a class="vue-link" v-on:click="signIn()">Sign In</a></li>
        <li v-if="isAuthenticated"><a class="vue-link" v-on:click="signOut()">Sign Out</a></li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: 'NavComponent',
  computed: {
    isAuthenticated() {
      // UserStore is installed as a plugin
      return this.$store.getters['account/isAuthenticated']
    },
  },
  methods: {
    computeCurrentClass: function(path) {
      if (this.$route.path === path) {
        return 'current'
      }
      return ''
    },
    signIn: function () {
      Auth.federatedSignIn().catch((err) => console.log(err))
    },
    signOut: function () {
      Auth.signOut().catch((err) => console.log(err))
    },
  },
};
</script>

<style scoped>
</style>
