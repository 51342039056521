<template>
  <footer>
    <div id="footer">
      <div class="container">
        <div class="row">
          <section class="col-3 col-6-narrower col-12-mobilep">
            <h3>Locations</h3>
            <ul class="links">
              <li><p><strong>Devoteam Revolve Paris</strong><br />43 boulevard Barbès<br />75018 Paris</p></li>
              <li><p><strong>Devoteam Revolve Toulouse</strong><br />30 rue de Metz<br />31 000 Toulouse</p></li>
            </ul>
          </section>
          <section class="col-3 col-6-narrower col-12-mobilep">
            <h3>&nbsp;</h3>
            <ul class="links">
              <li><p><strong>Devoteam Revolve Lyon</strong><br />26 Rue du Général Mouton-Duvernet<br />69003 Lyon</p></li>
              <li><p><strong>Devoteam Revolve Nantes</strong><br />3-4 Place Albert Camus<br />44000  Nantes</p></li>
            </ul>
          </section>
          <section class="col-3 col-12-narrower">
          </section>
          <section class="col-3 col-12-narrower">
            <h3>Contact us</h3>
            <ul class="links">
              <li>AWS training : 07 57 41 91 76</li>
              <li>Email: contact@revolve.team</li>
            </ul>
          </section>
        </div>
      </div>

      <!-- Icons -->
      <ul class="icons">
        <li>
          <a href="https://twitter.com/DevoteamRevolve" class="icon brands fa-twitter"
            ><span class="label">Twitter</span></a
          >
        </li>
        <li>
          <a href="https://github.com/d2si" class="icon brands fa-github"
            ><span class="label">GitHub</span></a
          >
        </li>
        <li>
          <a href="https://www.linkedin.com/company/devoteam-revolve" class="icon brands fa-linkedin-in"
            ><span class="label">LinkedIn</span></a
          >
        </li>
        <li>
          <a href="http://www.youtube.com/c/DevoteamRevolve" class="icon brands fa-youtube"
            ><span class="label">YouTube</span></a
          >
        </li>
        <li>
          <a href="https://www.twitch.tv/revolvestream" class="icon brands fa-twitch"
            ><span class="label">Twitch</span></a
          >
        </li>
      </ul>

      <!-- Copyright -->
      <div class="copyright">
        <ul class="menu">
          <li>&copy; Devoteam Revolve. All rights reserved</li>
          <li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

<style scoped>
</style>
