<template>
  <main>
    <!-- Banner -->
    <section id="banner">
      <header>
        <h2>
          Revolve Artifacts:
          <em>Your Revolve softwares, in one place</em>
        </h2>
        <router-link to="/main/learn-more" class="button">Learn More</router-link>
      </header>
    </section>
  </main>
</template>

<script>

export default {
  name: 'HomeView',
  components: {},
  computed: {
    isAuthenticated() {
      // UserStore is installed as a plugin
      return this.$userStore.state.userEmail != null;
    },
  },
};
</script>

<style scoped>
</style>
