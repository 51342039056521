<template>
  <div>
    <!-- Highlights -->
    <section class="style1">
      <div class="container">
        <div class="row gtr-200">
          <section class="col-4 col-12-narrower">
            <div class="box highlight">
              <i class="icon solid major fa-paper-plane"></i>
              <h3>Download your softwares</h3>
              <p>
                The artifact platform exposes your Revolve software purchases in
                one place. From here, you can view and download your purchsed
                content by yourself, at any time.
              </p>
            </div>
          </section>
          <section class="col-4 col-12-narrower">
            <div class="box highlight">
              <i class="icon solid major fa-pencil-alt"></i>
              <h3>Get the latest documentation</h3>
              <p>
                Each software version comes with a detailed online
                documentation. The artifacts documentation will be updated
                continuously, following users' feedbacks and software
                improvements.
              </p>
            </div>
          </section>
          <section class="col-4 col-12-narrower">
            <div class="box highlight">
              <i class="icon solid major fa-wrench"></i>
              <h3>Keep your content up-to-date</h3>
              <p>
                The artifact platform exposes all the content you purchased,
                along with continuous updates if your support plan includes it.
              </p>
            </div>
          </section>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "LearnMoreView",
};
</script>

<style scoped>
</style>
